import { useUserState } from 'hooks/state/useUserState';
import { MobileHeaderGlobalNavigationPresenter } from './presenter';
import { useEffect, useState } from 'react';
import { signout } from 'lib/api/users/signout';
import { INITIAL_USER_STATE } from 'globalStates/atoms/user';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

export function MobileHeaderGlobalNavigation() {
	const [user, setUser] = useUserState();
	const router = useRouter();
	const [isVisible, setVisible] = useState(false);

	async function logout() {
		if (await signout(user.token)) {
			setUser(INITIAL_USER_STATE);
			router.push('/login');
			return;
		}
		toast.error('ログアウトに失敗しました');
	}

	useEffect(() => {
		const handleRouteChange = () => {
			setVisible(false);
		};
		router.events.on('routeChangeComplete', handleRouteChange);
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router.events]);

	return (
		<MobileHeaderGlobalNavigationPresenter
			user={user}
			isVisible={isVisible}
			setVisible={() => setVisible(!isVisible)}
			logout={() => logout()}
		/>
	);
}
